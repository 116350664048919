import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route }  from "react-router-dom";
import { ethers } from "ethers";
import config from "./config.json";
import Header from "./Header.js";
import Stake from "./Stake.js";
import Statistics from "./Statistics.js";
import StakeList from "./StakeList.js";
import FAQ from "./FAQ.js";

import "./css/Global.css";
import "./css/Header.css";
import "./css/Stake.css";
import "./css/StakeList.css";
import "./css/Statistics.css";
import "./css/FAQ.css";

class App extends Component {
    state = {
        provider: null,
        signer: null,
        network: null,
        walletAddress: null,
        loading: true
    }

    constructor(props) {
        super(props);
        this.connect = this.connect.bind(this);
        this.update = this.update.bind(this);
        this.stakeRef = React.createRef();
        this.stakeListRef = React.createRef();
    }

    async componentDidMount()
    {
        this.setState({ loading: false });
    }

    async update(totalTicketsUpdate = false, tickets1, tickets2) {
        /*if (totalTicketsUpdate) {
            if (this.stakeRef.current) {
                this.stakeRef.current.setTotalTickets(tickets1, tickets2);
            }
        } else {
            if (this.stakeListRef.current) {
                await this.stakeListRef.current.update();
            }
        }*/
    }


    
    async connect() 
    {
        try {
            const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = await new ethers.providers.Web3Provider(window.ethereum, "any");
            const signer = await provider.getSigner();
            const network = (await provider.getNetwork()).chainId;
            const walletAddress = await signer.getAddress();

            this.setState({ provider, signer, network, walletAddress });
        } catch (e) {
            console.log(e);
            alert(`Failed to connect metamask`);
        }

        window.ethereum.on("accountsChanged", accounts => {
            window.location.reload();
        });

        window.ethereum.on("chainChanged", async network => {
            await this.connect();
        });
    }

    render() 
    {
        return (
            <Router>
                <Header connect={this.connect} walletAddress={this.state.walletAddress} network={this.state.network}/>
                <div className="main-tab">
                    {this.state.network == config.chainId ?
                        (!this.state.loading &&
                            <Switch>
                                <Route exact path="/" render={(props) => 
                                    <div>
                                        <Statistics provider={this.state.provider} ref={this.statisticsRef} network={this.state.network} parentUpdate={this.update}/>
                                        <Stake signer={this.state.signer} ref={this.stakeRef} parentUpdate={this.update}/>
                                    </div>
                                }/> 

                                <Route exact path="/your-stakes" render={(props) => 
                                    <div>
                                        <StakeList signer={this.state.signer} ref={this.stakeListRef}/>
                                    </div>
                                }/> 
                            </Switch>
                        )
                    : 
                        <h2 style={{textAlign: "center"}}> 
                            Please connect metamask on ETH {config.chainId == 1 ? "mainnet" : "testnet kovan"} 
                        </h2>  
                    }
                </div>
            </Router>
        );
    }
}

export default App;