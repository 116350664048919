import { Component } from "react";
import { ethers } from "ethers";
import config from "./config.json";
import stakingAbi from "./abi/stakeVR.json";
import ERC20abi from "erc-20-abi";
import Utils from "./Utils.js";

import FAQ from "./FAQ.js";

class Stake extends Component {
    state = {
        tokenContract: null,
        stakingContract: null,
        balance: 0,
        approved: false,
        loading: true,
        processing: false,

        sharesBonus: null,
        shares: null,
        shareInPool: null,
        estimatedAPR: null,
        estimatedAverageAPR: null,
        estimatedMonthlyRewards: null,
        tickets1: null,
        tickets2: null,
        totalTickets1: null,
        totalTickets2: null,

        enteredAmount: 0,
        enteredDays: 0,
        validInputs: false,
        stakeResult: {
            state: 0, 
            message: ""
        },
        activeTimeout: null
    };

    constructor(props) {
        super(props);
        const tokenContract = new ethers.Contract(config.tokenAddress, ERC20abi, this.props.signer);
        const stakingContract = new ethers.Contract(config.contractAddress, stakingAbi, this.props.signer);
        this.state.tokenContract =  tokenContract;
        this.state.stakingContract = stakingContract;

        this.amountChanged = this.amountChanged.bind(this);
        this.daysChanged = this.daysChanged.bind(this);
        this.setMaxAmount = this.setMaxAmount.bind(this);
        this.setMaxDays = this.setMaxDays.bind(this);
    }

    async componentDidMount() {
        await this.getTokenInfo();

        this.setState({ loading: false, enteredAmount: this.state.balance, enteredDays: 365 });
        this.validateInputs();

        await this.getStakeInfo();
    }

    update() {
        if (this.state.activeTimeout) {
            clearTimeout(this.state.activeTimeout);
        }

        this.state.activeTimeout = setTimeout(() => {
            this.getStakeInfo();
        }, 300);
    }

    async getTokenInfo() {
        const address = await this.props.signer.getAddress();
        const balanceRaw = await this.state.tokenContract.balanceOf(address);
        const balance = Number(ethers.utils.formatUnits(balanceRaw));

        const allowance = await this.state.tokenContract.allowance(address, this.state.stakingContract.address);
        const approved = allowance.gt(ethers.utils.parseUnits("1000000000000"));

        if (balance < Number(this.state.enteredAmount)) this.state.enteredAmount = balance;

        this.setState({ balance, approved });
    }

    async getStakeInfo() {
        const enteredAmount = Number(this.state.enteredAmount);
        const enteredDays = Number(this.state.enteredDays);

        const sharesInfo = await this.state.stakingContract.calculateShares(
            ethers.utils.parseUnits(enteredAmount.toString()), 
            enteredDays
        );
        const shares = Number(ethers.utils.formatUnits(sharesInfo.shares));
        const longTermBonusRaw = Number(ethers.utils.formatUnits(sharesInfo.longTermBonus));
        const totalShares = Number(ethers.utils.formatUnits(await this.state.stakingContract.totalShares())) + shares;

        let currentMonth = 1 + Utils.monthDiff(new Date(config.startTimestamp * 1000), new Date(Date.now() - 86400 * 2000));
        if (currentMonth >= config.monthlyRewards.length) currentMonth = config.monthlyRewards.length - 1;
        
        const sharesBonus = shares / enteredAmount * 100 - 100; 
        const shareInPool = shares / totalShares * 100;
        const estimatedAPR = shareInPool * config.monthlyRewards[currentMonth] * 12 / enteredAmount;
        const estimatedAverageAPR = shareInPool * config.rewardsPerYear / enteredAmount;
        const estimatedMonthlyRewards = shareInPool * config.monthlyRewards[currentMonth] / 100;
        const tickets1 = Math.floor(shares / config.ticket1);
        const tickets2 = Math.floor(shares / config.ticket2);
        this.setState({ sharesBonus, shares, shareInPool, estimatedAPR, estimatedAverageAPR, estimatedMonthlyRewards, tickets1, tickets2 });
    }

    async stake() {
        if (!this.state.validInputs || this.state.processing) return;

        this.state.stakeResult.message = "";
        this.setState({ processing: true });

        if (!this.state.approved) {
            await this.approve();
            return;
        }

        try {
            const tx = await this.state.stakingContract.stake(
                ethers.utils.parseUnits(this.state.enteredAmount.toString()),
                this.state.enteredDays
            );
            await tx.wait();
            const stakeResult = {
                state: 0,
                message: "staking successful"
            }
            await this.props.parentUpdate();
            await this.getTokenInfo();
            await this.validateInputs();
            this.setState({ processing: false, stakeResult });
        } catch (e) {
            const stakeResult = {
                state: 1,
                message: "something went wrong"
            }
            console.log(e);
            this.setState({ processing: false, stakeResult });
        }


        this.setState({ processing: false });
    }

    async approve() {
        try {
            const tx = await this.state.tokenContract.approve(
                this.state.stakingContract.address,
                ethers.utils.parseUnits("1000000000000000")
            );
            await tx.wait();
            const stakeResult = {
                state: 0,
                message: "approving successful"
            }
            this.setState({ approved: true, processing: false, stakeResult });
        } catch (e) {
            console.log(e);
            const stakeResult = {
                state: 1,
                message: "something went wrong"
            }
            this.setState({ processing: false, stakeResult });
        }
    }

    setTotalTickets(totalTickets1, totalTickets2) {
        this.setState({ totalTickets1, totalTickets2 });
    }

    validateInputs() {
        const stakeResult = this.state.stakeResult;

        if (Number(this.state.enteredAmount) < Number(config.minStakeAmount)) {
            stakeResult.state = 1;
            stakeResult.message = `Minimum staking amount is ${config.minStakeAmount}`;
            this.setState({ validInputs: false, stakeResult });
            return;
        }

        if (Number(this.state.enteredAmount) > this.state.balance) {
            stakeResult.state = 1;
            stakeResult.message = `You don't have ${this.state.enteredAmount} VR`;
            this.setState({ validInputs: false, stakeResult });
            return;
        }

        if (Number(this.state.enteredDays) < config.minDays) {
            stakeResult.state = 1;
            stakeResult.message = `Minimum staking days is ${config.minDays}`;
            this.setState({ validInputs: false, stakeResult });
            return;
        }

        if (Number(this.state.enteredDays) > config.maxDays) {
            stakeResult.state = 1;
            stakeResult.message = `Maximum staking days is ${config.maxDays}`;
            this.setState({ validInputs: false, stakeResult });
            return;
        }

        stakeResult.state = 0;
        stakeResult.message = ``;
        this.setState({ validInputs: true, stakeResult });
    }

    amountChanged(event) {
        let newValue = event.currentTarget.value.replaceAll(",", "");
        if (isNaN(newValue) || newValue.toString().startsWith(0)) {
            return;
        }

        newValue = newValue > this.state.balance ? this.state.balance : newValue;
        if (newValue < 0) newValue = 0;
        this.state.enteredAmount = newValue;
        this.validateInputs();
        this.update();
        this.forceUpdate();
    }

    daysChanged(event) {
        let newValue = event.currentTarget.value.replaceAll(",", "").replaceAll(".", "");
        if (isNaN(newValue) || newValue.toString().startsWith(0)) {
            return;
        }

        if (newValue < 0) newValue = 0;
        if (newValue > config.maxDays) newValue = config.maxDays;

        this.state.enteredDays = newValue;
        this.validateInputs();
        this.update();
        this.forceUpdate();
    }

    setMaxAmount() {
        this.state.enteredAmount = this.state.balance;
        this.validateInputs();
        this.update();
        this.forceUpdate();
    }

    setMaxDays() {
        this.state.enteredDays = config.maxDays;
        this.validateInputs();
        this.update();
        this.forceUpdate();
    }
    
    render() 
    {
        return (
            (!this.state.loading &&
                <div>
                <div id="stake">
                    <div id="stake-top">
                        <div className="stake-input">
                            <div className="stake-input-title"> VR amount to stake </div>
                            <div className="slider-input">
                                <span className="slider-label left"> {config.minStakeAmount} </span>
                                <div className="slidecontainer">
                                    <input 
                                        className="slider"
                                        type="range"
                                        min={config.minStakeAmount} 
                                        max={this.state.balance} 
                                        value={Number(this.state.enteredAmount)}
                                        onChange={this.amountChanged}
                                    ></input>
                                </div>
                                <span className="slider-label right" onClick={(e) => this.setMaxAmount()}> {Utils.numberWithCommas(this.state.balance)} </span>
                            </div>
                            <input 
                                className="input-box"
                                type="text"
                                min={0} 
                                max={this.state.balance}
                                value={Utils.numberWithCommas(this.state.enteredAmount)}
                                onChange={this.amountChanged}
                            ></input>
                           </div>
                        <div className="stake-input">
                            <div className="stake-input-title"> Number of days you want to lock up for </div>
                            <div className="slider-input">
                                <span className="slider-label left"> {config.minDays} </span>
                                <div className="slidecontainer">
                                    <input 
                                        className="slider"
                                        type="range"
                                        min={config.minDays} 
                                        max={config.maxDays} 
                                        value={Number(this.state.enteredDays)}
                                        onChange={this.daysChanged}
                                    ></input>
                                </div>
                                <span className="slider-label right" onClick={(e) => this.setMaxDays()}> {config.maxDays} </span>
                            </div>
                            <input 
                                className="input-box"
                                type="text"
                                min={0} 
                                max={config.maxDays}
                                value={Utils.numberWithCommas(this.state.enteredDays)}
                                onChange={this.daysChanged}
                            ></input>
                        </div>
                        <br></br>
                        <ul className="label-value-list">
                            <li>
                                VR Shares Bonus: 
                                <span className="highlight-text">
                                    {Utils.fancyNumber(this.state.sharesBonus)}%
                                </span>
                            </li>
                            <li>
                                VR Shares:  
                                <span className="highlight-text">
                                    {Utils.fancyNumber(this.state.shares)}
                                </span>
                            </li>
                            <li>
                                Staker’s share in the VR Pool
                                <span className="highlight-text">
                                    {Utils.fancyNumber(this.state.shareInPool)}%
                                </span>
                            </li>
                            <br></br>

                            <li>
                                VR Lands Tickets:
                                <span className="highlight-text">
                                    {this.state.tickets1 !== null ? this.state.tickets1 : "N/A"}
                                </span>
                            </li>
                            <li>
                                Mystery Airdrops Tickets:
                                <span className="highlight-text">
                                    {this.state.tickets2 !== null ? this.state.tickets2 : "N/A"}
                                </span>
                            </li>
                            <br></br>
                            <li>
                                Estimated Annual Rewards:
                                <span className="highlight-text">
                                    {Utils.fancyNumber(this.state.estimatedAPR)}%
                                </span>
                            </li>
                            <li>
                                Estimated Annual Rewards (3 Years)
                                <span className="highlight-text">
                                    {Utils.fancyNumber(this.state.estimatedAverageAPR)}%
                                </span>
                            </li>
                            <li>
                                Your next estimated rewards:
                                <span className="highlight-text">
                                    {Utils.fancyNumber(this.state.estimatedMonthlyRewards)} VR
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div id="stake-bottom">
                        <div id="stake-button-wrap">
                            <button className="button" onClick={async (e) => this.stake()} 
                                style={!this.state.validInputs || this.state.processing ? {color: "gray", cursor: "not-allowed"} : {}}
                            >
                                {this.state.processing ? 
                                    <div className="loader"></div>
                                :
                                    this.state.approved ? "STAKE" : "APPROVE"
                                }
                            </button>
                        </div>

                        <div id="stake-result" className={(this.state.stakeResult.state > 0 ? "wrong " : "")}>
                            {this.state.stakeResult.message}
                        </div>
                        <div className="small-text" style={{textAlign: "center"}}> note: please read all the information below before staking </div>
                    </div>
                </div>
                <FAQ></FAQ>
                </div>
            )
        )
    }
}

export default Stake;