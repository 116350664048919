import { Component } from "react";
import { ethers } from "ethers";
import Web3 from "web3";
import config from "./config.json";
import stakingAbi from "./abi/stakeVR.json";
import ERC20abi from "erc-20-abi";

class Statistics extends Component {
    state = {
        totalStaked: null,
        totalStakedUnits: null,
        averageDaysStaked: null,
        totalTickets1: 0,
        totalTickets2: 0
    }

    async componentDidMount() {
        await this.update();
    }

    async getStakeEvents(web3, currentBlock) {
        const stakingContract = new web3.eth.Contract(stakingAbi, config.contractAddress);
        let fromBlock = this.props.network == 1 ? 13799241 : 28751992;
        const blocksPerDay = this.props.network == 1 ? 6496 : 6496 * 5;
        const period = blocksPerDay * 30;

        const calls = [];
        while (fromBlock < currentBlock) {
            const toBlock = fromBlock + period;
            calls.push(stakingContract.getPastEvents('EStake', { fromBlock, toBlock }));
            fromBlock = toBlock;
        }
        const results2d = await Promise.all(calls);
        const results = [].concat(...results2d);
        return results;
    }

    async update() {
        const web3 = new Web3(window.ethereum);
        const currentBlock = await web3.eth.getBlockNumber();

        const calls = [];
        calls.push(this.getStakeEvents(web3, currentBlock));
        const results = await Promise.all(calls);
        const stakeEvents = results[0];

        let stakedAmountSum = 0;
        let stakedDaysMulAmountsSum = 0;
        const sharesByAddresses = {};

        for (const stakeEvent of stakeEvents) {
            const stakeInfo = stakeEvent.returnValues;
            const currentTimestamp = Math.round(Date.now() / 1000);
            const secondsPerDay = this.props.network == 1 ? 86400 : 30;
            const lockEndTimestamp = Number(stakeInfo.lockTimestamp) + Number(stakeInfo.lockDays) * secondsPerDay;
            const stakedAmount = Number(ethers.utils.formatUnits(stakeInfo.amount));
            stakedAmountSum += stakedAmount;
            stakedDaysMulAmountsSum += stakedAmount * Number(stakeInfo.lockDays);

            if (!sharesByAddresses[stakeInfo.staker]) {
                sharesByAddresses[stakeInfo.staker] = ethers.BigNumber.from(0);
            }
            sharesByAddresses[stakeInfo.staker] = sharesByAddresses[stakeInfo.staker].add(stakeInfo.shares);
        }

        let totalTickets1 = 0;
        let totalTickets2 = 0;
        
        for (const i in sharesByAddresses) {
            totalTickets1 += Math.floor(Number(ethers.utils.formatUnits(sharesByAddresses[i].div(config.ticket1))));
            totalTickets2 += Math.floor(Number(ethers.utils.formatUnits(sharesByAddresses[i].div(config.ticket2))));
        }

        const average = Math.round(stakedDaysMulAmountsSum / stakedAmountSum);

        let totalStaked = await this.getTotalStakeAmount();

        let totalStakedUnits = "";
        if (totalStaked > 1000) {
            totalStakedUnits = "K";
            totalStaked /= 1000;
        }
        if (totalStaked > 1000) {
            totalStakedUnits = "M"
            totalStaked /= 1000;
        }

        this.props.parentUpdate(true, totalTickets1, totalTickets2);
        this.setState({ totalTickets1, totalTickets2, totalStaked, totalStakedUnits, averageDaysStaked: average });
    }

    async getTotalStakeAmount() {
        const tokenContract = new ethers.Contract(config.tokenAddress, ERC20abi, this.props.provider);
        const balance = Number(ethers.utils.formatUnits(await tokenContract.balanceOf(config.contractAddress)));
        return balance;
    }

    render() 
    {
        return (
            <div id="statistics">
                <div>
                    <div className="stats-value glowing">
                         {this.state.totalStaked ? 
                            this.state.totalStaked.toFixed(2) + this.state.totalStakedUnits
                            : "N/A"
                        } 
                    </div>
                    <div className="stats-label"> Total VR staked </div>
                </div>
                <div>
                    <div className="stats-value glowing">
                         {this.state.averageDaysStaked ? 
                            this.state.averageDaysStaked
                            : "N/A"
                        } 
                    </div>
                    <div className="stats-label"> Average days staked </div>
                </div>
            </div>
        )
    }
}

export default Statistics;