import { Component } from "react";
import { ethers } from "ethers";
import config from "./config.json";
import stakingAbi from "./abi/stakeVR.json";
import claimAbi from "./abi/claimVR.json";
import Utils from './Utils.js';

class StakeList extends Component {
    state = {
        stakingContract: null,
        stakeList: [],
        shares: null,
        estimatedMontlyReward: null,
        tickets1: null,
        tickets2: null,
        pendingReward: 0,

        loading: true,
        processing: -2,
        unstakeResult: {
            state: 0, 
            message: ""
        },

        claiming: false,
        claimResult: {
            state: 0,
            message: ""
        },
        alerted: false
    }

    constructor(props) {
        super(props);
        this.state.stakingContract = new ethers.Contract(config.contractAddress, stakingAbi, this.props.signer);
        this.state.claimContract = new ethers.Contract(config.claimVR, claimAbi, this.props.signer);
        this.state.claimContract2 = new ethers.Contract(config.claimVR2, claimAbi, this.props.signer);
    }

    async componentDidMount() {
        await this.update();
        this.setState({ loading: false });
    }

    async update() {
        const stakerAddress = await this.props.signer.getAddress();
        const stakesCount = Number(await this.state.stakingContract.stakerStakeCount(stakerAddress));

        const calls = [];
        for (let i = 0; i < stakesCount; i++) {
            calls.push(this.state.stakingContract.stakers(stakerAddress, i));
        }

        const result = await Promise.all(calls);

        const stakeList = [];
        const stakeInfoCalls = [];
        result.forEach((stake, i) => {
            if (stake.unstaked) return;

            const secondsPerDay = config.chainId == 1 ? 86400 : 60;

            const dateNow = Math.round(Date.now() / 1000);
            const remainingTime = Number(stake.lockTimestamp) + Number(stake.lockDays) * secondsPerDay - dateNow;
            let lockDaysLeft = Math.ceil(remainingTime / secondsPerDay);
            if (lockDaysLeft < 0) lockDaysLeft = 0;

            stakeList.push({
                index: lockDaysLeft == 0 ? i : -1,
                amount: Number(ethers.utils.formatUnits(stake.amount)),
                lockDays: Number(stake.lockDays),
                lockDaysLeft
            });

            stakeInfoCalls.push(this.state.stakingContract.calculateShares(
                stake.amount,
                stake.lockDays
            ));
        });

        
        let sharesSum = 0;
        let estimatedMontlyRewardSum = 0;

        let currentMonth = 1 + Utils.monthDiff(new Date(config.startTimestamp * 1000), new Date(Date.now() - 86400 * 2000));
        if (currentMonth >= config.monthlyRewards.length) currentMonth = config.monthlyRewards.length - 1;
        
        stakeInfoCalls.push(this.state.stakingContract.totalShares());
        const stakeInfoResult = await Promise.all(stakeInfoCalls);
        const totalShares = Number(ethers.utils.formatUnits(stakeInfoResult[stakeInfoResult.length - 1]));
        for (let i = 0; i < stakeList.length; i++) {
            const shares = Number(ethers.utils.formatUnits(stakeInfoResult[i].shares));
            const longTermBonusRaw = Number(ethers.utils.formatUnits(stakeInfoResult[i].longTermBonus));
            const longTermBonus = longTermBonusRaw / stakeList[i].amount * 100; 
            const shareInPool = shares / totalShares * 100;
            const estimatedAPR = shareInPool * config.monthlyRewards[currentMonth] * 12 / stakeList[i].amount;
            stakeList[i].longTermBonus = longTermBonus;
            stakeList[i].shareInPool = shareInPool;
            stakeList[i].estimatedAPR = estimatedAPR;
            stakeList[i].estimatedMonthlyRewards = shareInPool * config.monthlyRewards[currentMonth] / 100;

            sharesSum += shares;
            estimatedMontlyRewardSum += stakeList[i].estimatedMonthlyRewards;
        } 

        const tickets1 = Math.floor(sharesSum / config.ticket1);
        const tickets2 = Math.floor(sharesSum / config.ticket2);

        let pendingReward = 0;
        try {
            pendingReward = Number(ethers.utils.formatUnits(await this.state.claimContract2.callStatic.claimMultiple(stakerAddress, 
                Date.now() > 1706900400000 ? // start time of claimContract2
                [
                    this.state.claimContract.address,
                    this.state.claimContract2.address
                ] : [
                    this.state.claimContract.address
                ]
            )));
        } catch (e) {
        }

        this.setState({ stakeList, shares: sharesSum, estimatedMontlyReward: estimatedMontlyRewardSum, tickets1, tickets2, pendingReward });
    }

    async unstake(stakeIndex) {
        if (stakeIndex == -1 || this.state.processing >= 0) return;
        if (this.state.pendingReward > 0 && !this.state.alerted) {
            alert("Claim your rewards before unstaking!");
            this.setState({ alerted: true });
            return;
        }

        this.state.unstakeResult.message = "";
        this.setState({ processing: stakeIndex });

        try {
            const tx = await this.state.stakingContract.unstake(stakeIndex);
            await tx.wait();
            
            try {
                await this.update();
            } catch (e) {}
           
            const unstakeResult = {
                state: 0,
                message: "unstaking successful"
            }
            this.setState({ processing: -2, unstakeResult });
        } catch (e) {
            console.log(e);
            const unstakeResult = {
                state: 1,
                message: "something went wrong"
            }
            this.setState({ processing: -2, unstakeResult });
        }
    }

    async claim() {
        if (this.state.claiming || this.state.pendingReward == 0) return;
        const claimResult = {
            state: 1,
            message: ""
        }
        this.setState({ claiming: true, claimResult });

        try {
            const stakerAddress = await this.props.signer.getAddress();
            if (Date.now() > 1706900400000) {
                const tx = await this.state.claimContract2.claimMultiple(stakerAddress, [
                    this.state.claimContract.address,
                    this.state.claimContract2.address
                ]);
                await tx.wait();
            } else {
                const tx = await this.state.claimContract.claim(stakerAddress);
                await tx.wait();
            }

            const pendingReward = 0;
            const claimResult = {
                state: 0,
                message: "successfully claimed"
            }
            this.setState({ pendingReward, claimResult, claiming: false });

        } catch (e) {
            const claimResult = {
                state: 1,
                message: "something went wrong"
            }
            this.setState({ claimResult, claiming: false });
        }
    }

    render() 
    {
        const stakeList = this.state.stakeList.map((stake, i) => {
            let amount;
            if (stake.amount < 0.0001) {
                amount = "< 0.0001";
            } else {
                amount = stake.amount.toFixed(stake.amount > 1 ? 0 : 4);
                amount = Utils.numberWithCommas(amount);
            }
            
            let estimatedMonthlyRewards;
            if (stake.estimatedMonthlyRewards < 0.0001) {
                estimatedMonthlyRewards = "< 0.0001";
            } else {
                estimatedMonthlyRewards = stake.estimatedMonthlyRewards.toFixed(stake.estimatedMonthlyRewards > 1 ? 0 : 4);
                estimatedMonthlyRewards = Utils.numberWithCommas(estimatedMonthlyRewards);
            }
        
            return (
               <div className="active-stake" key={i}> 
                    <div>
                        <div className="top"> 
                            {amount} VR for {stake.lockDays} days ({stake.lockDaysLeft} days left)
                        </div>
                        <ul className="label-value-list bottom small-text">
                            <li>
                                Share in the pool: 
                                <span className="highlight-text">
                                    {Utils.fancyNumber(stake.shareInPool)}%
                                </span>
                            </li>
                            <li>
                                Estimated Annual Rewards: 
                                <span className="highlight-text"> 
                                    {Utils.fancyNumber(stake.estimatedAPR)}%
                                </span>
                            </li>
                            <li>
                                Estimated monthly rewards: 
                                <span className="highlight-text"> 
                                    {Utils.fancyNumber(stake.estimatedMonthlyRewards)} VR
                                </span>
                            </li>
                        </ul>
                    </div>
                    <button 
                        className={"button" + (this.state.processing > -1 || stake.index == -1 ? " disabled": "")}
                        onClick={async (e) => this.unstake(stake.index)}
                    >
                        {this.state.processing == stake.index ? <div className="loader"></div> : "UNSTAKE"}
                    </button>
               </div>
           ) 
        });
        return (
            <div id="stake-list">
                <h2> Your stakes </h2>
                <div id="stake-list-summary">
                    <ul className="label-value-list">
                        <li>
                           VR shares: 
                            <span className="highlight-text">
                                {Utils.fancyNumber(this.state.shares)}
                            </span>
                        </li>
                        <li>
                            Estimated monthly reward: 
                            <span className="highlight-text"> 
                                {Utils.fancyNumber(this.state.estimatedMontlyReward)} VR
                            </span>
                        </li>
                        <br></br>
                        <li>
                            VR Lands Tickets: 
                            <span className="highlight-text"> 
                                {this.state.tickets1 !== null ? this.state.tickets1 : "N/A"}
                            </span>
                        </li>
                        <li>
                            Mystery Airdrops Tickets: 
                            <span className="highlight-text"> 
                                {this.state.tickets2 !== null ? this.state.tickets2 : "N/A"}
                            </span>
                        </li>
                    </ul>
                    <br></br>
                    <div id="claim"> 
                        <div id="pending-reward"> 
                            Pending reward:
                            <span className="highlight-text"> {Utils.fancyNumber(this.state.pendingReward)} VR </span>
                        </div>
                        <button className={"button" + (this.state.pendingReward > 0 ? "" : " disabled")}  
                            onClick={async (e) => this.claim()}
                        >  
                            {this.state.claiming ? <div className="loader"></div> : "CLAIM"}
                        </button>
                        <div id="claim-result" className={(this.state.claimResult.state > 0 ? "wrong " : "")}>
                            {this.state.claimResult.message}
                        </div>
                    </div>
                </div>
                <div id="unstake-result" className={(this.state.unstakeResult.state > 0 ? "wrong " : "")}>
                    {this.state.unstakeResult.message}
                </div>
                {!this.state.loading &&
                    <div>
                        {stakeList}
                    </div>
                }
            </div>
        )
    }
}

export default StakeList;